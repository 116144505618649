import React from 'react';
import Layout from 'components/shared/layout';
import Breadcrumb from 'components/shared/breadcrumb';
import { graphql } from 'gatsby';
import SectionContainer from 'components/shared/section-container';
import Hat from 'components/shared/hat';
import SectionTitle from 'components/shared/section-title';
import theme from 'src/shared/theme';
import { pageSideMargin } from 'src/shared/styles/page-margin';
import NewsCards from 'components/shared/news-cards';
import styled from 'styled-components';

const Content = styled.div`
  ${pageSideMargin};
  margin-top: ${theme.spacing(16)};
  margin-bottom: ${theme.spacing(22)};
`;

const Cards = styled(NewsCards)`
  padding-top: ${theme.spacing(8)};
`;

const NewsPage = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const { title } = data.markdownRemark.frontmatter;
  const newsPosts = data.allMarkdownRemark.edges.map(edge => ({
    slug: edge.node.fields.slug,
    date: new Date(edge.node.frontmatter.date),
    title: edge.node.frontmatter.title,
    description: edge.node.frontmatter.description,
  }));

  const sortedPosts = newsPosts.sort((a, b) => a.date > b.date);

  return (
    <>
      <Hat pageName={title} />
      <Layout>
        <Breadcrumb crumbs={crumbs} />
        <SectionContainer>
          <Content>
            <SectionTitle align="left">{title}</SectionTitle>
            <Cards newestPosts={sortedPosts} />
          </Content>
        </SectionContainer>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { id: { eq: "news-page" } }) {
      frontmatter {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { fileAbsolutePath: { regex: "/(/aktualne-u-nas/)/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
          }
        }
      }
    }
  }
`;

export default NewsPage;
